import { Environment } from "../app/model/environment";

export const environment: Environment = {
  production: true,
  pilot: false,
  demo: false,
  mock: false,

  apiUrl: 'https://ong-qa-api.pipelinedl.com',

  spreadLicense:
    // tslint:disable-next-line: max-line-length
    'Empyrean Solutions,445182643533491#B0ATeV5Y5oke9lGNH3WM7JnVQF7Vzx6Zq3EZoJkQoN5RiF5VplVW8wEVCNmSvhVM5t6KlJnVjVncOpkS7U6YQZWbEBXUwcjZGpXMXZ4UaZWezITN6kUMwtUOLtSM7s4NxBDMohGeTRkR7xUMI9EOTRmQGhlVKRVRUFkbGZTOPRndzMXdpJ5bEFUahFlcBhzaOBDT9czUOZFV094byZmW7QzbFpmNVF4YmVDTvpWR5YUesN5LTVDbExmRzdEUtRDTXtWaSx6QpVTSRdlW7QGWCtiV9dUe63GbPpndwEGS4R4b5N6ZiojITJCLigTMBJ4QyEENiojIIJCLwkzMyUjNzYDN0IicfJye#4Xfd5nIJZUOCJiOiMkIsIiMx8idgMlSgQWYlJHcTJiOi8kI1tlOiQmcQJCLiUjM6ITNwASMwcDM9EDMyIiOiQncDJCLiMnbvlGd5x6bTBibhVmc9BXbFJiOiEmTDJCLiETO4MzM5MDN6IDOxUDN4IiOiQWSiwSflNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRVXOklzbOdncn9mRmZzRuh4VIFFWJhFaINTbwpmbNNmbR5UQkFWVkNzNJRVYv5GS8UmVTlEaVlVR7J4bTh7bRdzb9dGcDNkNqpVWQVjZHRTVlFnbxYVWrgGWah6d724QI36T4l6K64UTyhFUKJ4KGJrZ3l',

  gridHighlight: '#EDF4FC',
  gridBackground: 'white',
  gridCellChanged: '#FFE6A2',
  gridCellBorder: '#E0E0E0',
  buttonEnabled: '#468EE5',
  buttonEnabledText: 'white',
  buttonDisabled: 'rgba(0,0,0,0.05)',
  buttonDisabledText: '#CCCCCC',

  // DEFINE USER ROLES CONSTANTS
  ROLE_ADMIN: -1,
  ROLE_PUBLIC: 0,
  ROLE_REGULATOR: 1,
  ROLE_MRO: 2,
  ROLE_MRO_PROXY: 4,
  ROLE_MRO_PROXY_ADMIN: 8,
  ROLE_BANK: 16,
  ROLE_BANK_ADMIN: 32,
  ROLE_OPERATOR: 64,
  ROLE_OPERATOR_ADMIN: 128,

  // FOR ADB2C CONFIGURATION
  B2C_POLICY_NAME_SIGN_IN: 'b2c_1_sign_in',
  B2C_POLICY_NAME_RESET_PASSWORD: 'B2C_1_reset_password',
  B2C_AUTHORITY_URL_SIGN_IN: 'https://oilngasqa.b2clogin.com/oilngasqa.onmicrosoft.com/b2c_1_sign_in',
  B2C_AUTHORITY_URL_RESET_PASSWORD: 'https://oilngasqa.b2clogin.com/oilngasqa.onmicrosoft.com/B2C_1_reset_password',
  B2C_AUTHORITY_DOMAIN_URL: 'oilngasqa.b2clogin.com',
  B2C_CLIENT_ID: '5d3bf18a-416f-41c1-86d4-4c52797a7c00',

  B2C_ACTIVATION: 'https://oilngasqa.b2clogin.com/oilngasdev.onmicrosoft.com/B2C_1A_AM_useractivation',

};
